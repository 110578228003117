import store from "./store";
import { getCookies, deleteCookie } from "@/helpers/store-cookies";

const moduleRoutes = [
  {
    path: "/login",
    component: () =>
      import(
        /* webpackChunkName: "login", webpackPrefetch: true */ "./views/Login.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (!store.state.token) {
        // if(!JSON.parse(localStorage.getItem('otp_done'))) {
        //     localStorage.setItem('layout', 'landing-layout')
        //     next('/otp');
        //     return;
        // }
        localStorage.setItem("layout", "landing-layout");
        next();
        return;
      }
      localStorage.setItem("layout", "dashboard-layout");
      next("/dashboard");
    },
  },

  {
    path: "/signup",
    component: () =>
      import(
        /* webpackChunkName: "signup", webpackPrefetch: true */ "./views/SignUp.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (!store.state.token) {
        localStorage.setItem("layout", "landing-layout");
        next();
        return;
      }
      localStorage.setItem("layout", "dashboard-layout");
      next("/dashboard");
    },
  },
  {
    path: "/coming-soon",
    component: () =>
      import(
        /* webpackChunkName: "signup", webpackPrefetch: true */ "@/layouts/ComingSoon.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (!store.state.token) {
        localStorage.setItem("layout", "landing-layout");
        next();
        return;
      }
      localStorage.setItem("layout", "dashboard-layout");
      next("/dashboard");
    },
  },

  {
    path: "/cloudtech23-register-form",
    component: () =>
      import(
        /* webpackChunkName: "signup", webpackPrefetch: true */ "./views/cloudtech23RegisterForm.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (!store.state.token) {
        localStorage.setItem("layout", "landing-layout");
        next();
        return;
      }
      localStorage.setItem("layout", "dashboard-layout");
      next("/dashboard");
    },
  },

  {
    path: "/otp",
    component: () =>
      import(
        /* webpackChunkName: "otp", webpackPrefetch: true */ "./views/OtpVerify.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (!store.state.token) {
        if (!JSON.parse(localStorage.getItem("signup_done"))) {
          localStorage.setItem("layout", "landing-layout");
          next("/signup");
          return;
        }
        localStorage.setItem("layout", "landing-layout");
        next();
        return;
      }
      localStorage.setItem("layout", "dashboard-layout");
      next("/dashboard");
    },
  },

  // {
  //     path: "/event2022",
  //     component: () => import('./views/Event2022.vue'),
  //     beforeEnter: (to, from, next) => {
  //         if(!store.state.token) {
  //             localStorage.setItem('layout', 'landing-layout');
  //             next();
  //             return;
  //         }
  //         localStorage.setItem('layout', 'dashboard-layout');
  //         next('/dashboard');
  //     }
  // },

  {
    path: "/enterEmail",
    component: () =>
      import(
        /* webpackChunkName: "enterEmail", webpackPrefetch: true */ "./views/EnterEmail.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (!store.state.token) {
        localStorage.setItem("layout", "landing-layout");
        next();
        return;
      }
      localStorage.setItem("layout", "dashboard-layout");
      next("/dashboard");
    },
  },

  {
    path: "/confirm-invitation/:tenantId/:email",
    component: () =>
      import(
        /* webpackChunkName: "confirm-invitation", webpackPrefetch: true */ "./views/ConfirmInvitation.vue"
      ),
    beforeEnter: (to, from, next) => {
      // if(!store.state.token) {
      //     localStorage.setItem('layout', 'landing-layout');
      //     next();
      //     return;
      // }
      deleteCookie("access_token");
      localStorage.removeItem("FullName");
      localStorage.setItem("layout", "landing-layout");
      next();
    },
  },

  {
    path: "/thank-you",
    component: () =>
      import(
        /* webpackChunkName: "thank-you", webpackPrefetch: true */ "./views/ThankYou.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (!store.state.token) {
        localStorage.setItem("layout", "landing-layout");
        next();
        return;
      }
      if (getCookies("access_token")) {
        localStorage.setItem("layout", "dashboard-layout");
        next("/dashboard");
      }
      localStorage.setItem("layout", "dashboard-layout");
      next("/dashboard");
    },
  },

  {
    path: "/reset-paswsword",
    component: () =>
      import(
        /* webpackChunkName: "reset-paswsword", webpackPrefetch: true */ "./views/ResetPassword.vue"
      ),
    beforeEnter: (to, from, next) => {
      
      // if (!store.state.token) {
      //   localStorage.setItem("layout", "landing-layout");
      //   next();
      //   return;
      // }
      // localStorage.setItem("layout", "dashboard-layout");
      // next("/dashboard");

      localStorage.setItem("layout", "landing-layout");
      next();
    },
  },
];

export default (router) => {
  router.addRoutes(moduleRoutes);
};
