import { AXIOS } from "../../../axios";
import Vue from "vue";
import { successToast } from "@/helpers/toastifications";
import ls from "localstorage-slim";
ls.config.encrypt = true;

export default {
  namespaced: true,
  state: {
    profitAndLossLoading: true,
    profitAndLossObj: {},
    personal_notes: [],
    personal_notes_loading: true,
    bankAccounts: [],
    bankLoading: true,
    customersAndVendorsForJournalEntry: [],
    journalEntryNextItem: "",
    currency_data: {},
    customer: [],
    SystemReminder: [],
    reminderById: {},
    loading: true,
  },
  getters: {
    profitAndLossLoading: (state) => state.profitAndLossLoading,
    profitAndLossObj: (state) => state.profitAndLossObj,
    personal_notes: (state) => state.personal_notes,
    personal_notes_loading: (state) => state.personal_notes_loading,
    bankAccounts: (state) => state.bankAccounts,
    bankLoading: (state) => state.bankLoading,
    customersAndVendorsForJournalEntry: (state) =>
      state.customersAndVendorsForJournalEntry,
    journalEntryNextItem: (state) => state.journalEntryNextItem,
    currency_data: (state) => state.currency_data,
    customer: (state) => state.customer,
    SystemReminder: (state) => state.SystemReminder,
    reminderById: (state) => state.reminderById,
    loading: (state) => state.loading,
  },
  mutations: {
    set_profitAndLossLoading: (state, data) => {
      state.profitAndLossLoading = data;
    },

    set_profitAndLossObj: (state, data) => {
      state.profitAndLossObj = data;
    },

    set_personal_notes: (state, data) => {
      state.personal_notes = data;
    },

    set_system_reminders: (state, data) => {
      state.SystemReminder = data;
    },

    set_personal_notes_loading: (state, data) => {
      state.personal_notes_loading = data;
    },

    set_bankAccounts: (state, data) => {
      state.bankAccounts = data;
    },

    set_bankLoading: (state, data) => {
      state.bankLoading = data;
    },

    set_customersAndVendorsForJournalEntry: (state, data) => {
      state.customersAndVendorsForJournalEntry = data;
    },

    set_customer: (state, data) => {
      state.customer = data;
    },

    set_journalEntryNextItem: (state, data) => {
      state.journalEntryNextItem = data;
    },

    set_currency_data: (state, data) => {
      state.currency_data = data;
    },
    set_reminderId: (state, data) => {
      state.reminderById = data;
    },
    loadingData: (state, data) => {
      state.loading = data;
    },
  },
  actions: {
    openAddNotePopup() {
      const addNoteModal = document.getElementById("addNoteModal");
      addNoteModal.style.display = "flex";
    },

    openEditReminderPopup() {
      const editReminderModal = document.getElementById("editReminderModal");
      editReminderModal.style.display = "flex";
    },

    closeAddNotePopup() {
      const addNoteModal = document.getElementById("addNoteModal");
      addNoteModal.style.display = "none";
    },
    closeAddReminderPopup() {
      const addReminderModal = document.getElementById("addReminderModal");
      addReminderModal.style.display = "none";
    },

    closeEditReminderPopup() {
      const editReminderModal = document.getElementById("editReminderModal");
      editReminderModal.style.display = "none";
    },

    get_profitAndLossObj_action: ({ commit }) => {
      AXIOS.post("Reports/ProfitAndLossChartDashboard", {})
        .then((res) => {
          commit("set_profitAndLossObj", res.data);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_profitAndLossLoading", false);
        });
    },

    get_personal_notes_action: ({ commit }, lang) => {
      AXIOS.post(
        "Reminder/GetReminder",
        {},
        {
          headers: {
            // Add your headers here
            "accept-language": `${lang}`,
            // Other headers as needed
          },
        }
      )
        .then((res) => {
          commit("set_personal_notes", res.data.UserReminder);
          commit("set_system_reminders", res.data.SystemReminder);
          ls.set("randomID", res.data.DaysTrail);
          commit("set_personal_notes_loading", false);
        })
        .catch((err) => {
          err.message;
        });
      // .finally(() => {
      //     commit('set_personal_notes_loading', false)
      // })
    },

    EditReminder: ({ commit }, id) => {
      commit("loadingData", true);
      AXIOS.post(`Reminder/GetReminderByid/${id}`)
        .then((res) => {
          commit("set_reminderId", res.data.ReminderById);
          commit("loadingData", false);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {});
    },

    deleteReminder: ({ dispatch, commit }, id) => {
      AXIOS.post(`Reminder/DeleteReminder/${id}`)
        .then((res) => {
          if (res.status == 200) {
            dispatch("closeAddReminderPopup");
            dispatch(
              "get_personal_notes_action",
              localStorage.getItem("locale")
            );
            successToast("Reminder Deleted Successfully!");
          }
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {});
    },

    add_note_action: ({ dispatch }, payload) => {
      AXIOS.post("Reminder/AddReminder", {
        subject: payload.subject,
        comment: payload.comments,
        priority: payload.priority,
      })
        .then((res) => {
          if (res.status == 200) {
            // Vue.$toast("Note Added Successfully!", {
            //     timeout: 5000
            // });
            successToast("Note Added Successfully!");
            dispatch("closeAddNotePopup");
            dispatch(
              "get_personal_notes_action",
              localStorage.getItem("locale")
            );
          }
        })
        .catch((err) => {
          err.message;
        });
    },

    add_reminder: ({ dispatch }, payload) => {
      return AXIOS.post("Reminder/AddReminder", {
        reminder_title: payload.subject,
        reminder_description: payload.comments,
        due_date: payload.due_date,
        priority: payload.priority,
        add_reminder: payload.add_reminder,
        Is_send: payload.Is_send,
        email: payload.email,
      })
        .then((res) => {
          if (res.status == 200) {
            dispatch("closeAddNotePopup");
            dispatch(
              "get_personal_notes_action",
              localStorage.getItem("locale")
            );
          }
          return res;
        })
        .catch((err) => {
          err.message;
        });
    },

    UpdateReminder: ({ dispatch }, payload) => {
      return AXIOS.post(`Reminder/UpdateReminder/${payload.id}`, {
        reminder_title: payload.subject,
        reminder_description: payload.comments,
        due_date: payload.due_date,
        priority: payload.priority,
        add_reminder: payload.add_reminder,
        email: payload.email,
      })
        .then((res) => {
          if (res.status == 200) {
            dispatch("closeAddReminderPopup");
            dispatch(
              "get_personal_notes_action",
              localStorage.getItem("locale")
            );
          }
          return res;
        })
        .catch((err) => {
          err.message;
        });
    },

    get_bankAccounts_action: ({ commit }) => {
      AXIOS.post("Dashboard/BankTransactionsTable", {})
        .then((res) => {
          commit("set_bankAccounts", res.data.COAList);
        })
        .catch((err) => {
          err.message;
        })
        .finally(() => {
          commit("set_bankLoading", false);
        });
    },

    get_customersAndVendorsForJournalEntry_action: ({ commit }) => {
      AXIOS.get("Forms/ListOFNameJournalEntries")
        .then((res) => {
          commit(
            "set_customersAndVendorsForJournalEntry",
            res.data.CustomerVendorData
          );
          // commit('set_journalEntryNextItem', res.data.NextItem)
        })
        .catch((err) => {
          err.message;
        });
    },
    get_JournalEnteryNumber: ({ commit }) => {
      AXIOS.get("JournalEntry/GetNextJournalNumber")
        .then((res) => {
          commit("set_journalEntryNextItem", res.data);
        })
        .catch((err) => {
          err.message;
        });
    },

    // get_customer_action:({commit}) => {
    //     AXIOS.get('Forms/GetCustomerForInvoice')
    //     .then(res => {
    //         commit('set_customer', res.data.Customers)
    //         commit('set_journalEntryNextItem', res.data.NextItem)
    //     })
    //     .catch(err => {
    //         (err.message);
    //     })
    // },

    // set_currency_data_action: ({commit}, data) => {
    //     commit('set_currency_data', data)
    // }
  },
  modules: {},
};
