import store from "../users/store";

const moduleRoute = {
  path: "/expenses",
  component: () =>
    import(
      /* webpackChunkName: "expenses-module", webpackPrefetch: true */ "./Module.vue"
    ),
  children: [
    {
      path: "/",
      component: () =>
        import(
          /* webpackChunkName: "expenses-home", webpackPrefetch: true */ "./views/Home.vue"
        ),
    },
    {
      path: "/expenses/add",
      component: () =>
        import(
          // /* webpackChunkName: "expenses-view", webpackPrefetch: true */ "./views/ExpenseForm.vue"
          /* webpackChunkName: "expenses-view", webpackPrefetch: true */ "./views/Add/index.vue"
        ),
    },

    {
      path: "/expenses/:id",
      component: () =>
        import(
          // /* webpackChunkName: "expenses-view", webpackPrefetch: true */ "./views/ExpenseForm.vue"
          /* webpackChunkName: "expenses-view", webpackPrefetch: true */ "./views/view/index.vue"
        ),
    },
    {
      path: "/expenses/:id/edit",
      name: "EditExpense",
      component: () =>
        import(
          /* webpackChunkName: "expenses-view", webpackPrefetch: true */ "./views/Edit/index.vue"
        ),
    },
    {
      path: "/expenses/preview",
      component: () =>
        import(
          /* webpackChunkName: "expenses-view", webpackPrefetch: true */ "./views/preview.vue"
        ),
    },

    // {
    //   path: "/expenses/:id",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "expenses-view", webpackPrefetch: true */ "./views/ExpenseForm.vue"
    //     ),
    // },
  ],
  beforeEnter: (to, from, next) => {
    if (store.state.token) {
      localStorage.setItem("layout", "dashboard-layout");
      next();
      return;
    }
    localStorage.setItem("layout", "landing-layout");
    next("/login");
  },
};

export default (router) => {
  router.addRoute(moduleRoute);
};
