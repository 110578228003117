import axios from "axios";
import app from "@/env";
import { getCookies } from "@/helpers/store-cookies";
import ls from "localstorage-slim";

export const AXIOS = axios.create({
  // staging baseUrl
  // baseURL: `/`,

  // production baseUrl
  baseURL: app.baseURL,
  // https://production.tas-technologies.com/ , https://production.tas-technologies.com/
  headers: {
    Authorization: `Bearer ${getCookies("access_token")}`,
    // Accept: 'application/json',
    ContentType: "application/x-www-form-urlencoded",
    XTenant: `${localStorage.getItem("xtenant")}`,
    // "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Allow-Headers": "*",
    "Accept-Language": ls.get("locale"),
  },
  // localStorage.getItem('xtenant') ||
});
